import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localeTh from '@angular/common/locales/th';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

// import { LoggerModule } from 'ngx-logger';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WayLibModule } from '@way-lib/way-lib.module';
import { HttpLoaderFactory } from '@way-lib/common/services/http-loader.service';
import { AuthGuard } from '@way-lib/common/services/auth/auth.guard';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import { NgxLoggerConfig } from '../config/ngx-logger/ngx-logger-config';

import { environment } from '../environments/environment';
import { MenuService } from './services/menu.service';

registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeNl);
registerLocaleData(localeTh);

const firebaseConfig = {
  apiKey           : "AIzaSyBKkNRKvCRR5oZy711RKfHxKfdUyRPZ1WA",
  authDomain       : "way-internal.firebaseapp.com",
  projectId        : "way-internal",
  storageBucket    : "way-internal.appspot.com",
  messagingSenderId: "418004973783",
  appId            : "1:418004973783:web:5dbb280752339054821892"
}

@NgModule({
  declarations: [AppComponent],
  imports     : [
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    AngularFireMessagingModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name       : 'way-internal',
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB]
    }),
    BrowserModule,
    BrowserAnimationsModule,
    WayLibModule,
    AppRoutingModule,
    HttpClientModule,
    // LoggerModule.forRoot(NgxLoggerConfig),
    TranslateModule.forRoot({
      loader: {
        provide   : TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps      : [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: firebaseConfig },
    {
      provide : RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide : 'apiRootUrl',
      useValue: environment.apiRootUrl,
    },
    {
      provide : 'WaySuffix',
      useValue: 'Internal',
    },
    {
      provide : 'disableMenu',
      useValue: true,
    },
    {
      provide : 'menuService',
      useClass: MenuService,
    },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}